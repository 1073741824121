import React from 'react'
import { CloseIcon } from '../Icon'

import './index.scss'

interface ModalProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({ children, open, onClose }) => {
  const onCloseClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation()
    onClose?.()
  }

  return (
    <div className={`modal ${open ? '' : 'hide'}`}>
      <div className="modal-content-wrapper">
        <CloseIcon onClick={onCloseClick}></CloseIcon>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  )
}

export default Modal
